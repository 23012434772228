import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axiosAuth from "../axios/Interceptors";
import AppContext from "../Context/Context";
import ElapsedTime from "./ElapsedTime";
import { useGlobalState } from "../Context/GlobalStateContext";
import moment from "moment";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { Modal } from "react-bootstrap";

type Props = {
  MGEventID: any;
  SiteUserID: any;
  MGProjectID: any;
  StartDateTime: any;
  EndDateTime: any;
  expandView: boolean;
};
export default function TimeLogDetailView(props: Props) {
  const globalState = useGlobalState();
  const [timerObject, setTimerObject] = useState({
    mgeventid: 0,
    siteuserid: 0,
    name: 0,
    startdatetime: 0,
    enddatetime: 0,
    durationinminutes: 0,
    wastageminutes: 0,
    wastagecomments: "",
    staffcomments: "",
    managementcomments: "",
    ismanualentry: 0,
    manualentrytime: 0,
    manuallyenteredbyid: 0,
    ptorderby: 0,
    objectstatus: 0,
  });

  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  const [loadingState, setLoadingState] = useState(false);
  const [logsDetailData, setLogsDetailData] = useState<any>([]);

  const [deletePopupObject, setDeletePopupObject] = useState({
    showDeletePopUp: false,
    seletedID: 0,
    seletedObject: {},
  });

  const [filtersDropdownData, setFiltersDropdownData] = useState({
    ProjectData: [],
    UserData: [],
  });

  // useEffect(() => {
  //   //OpenloadMGTimeLogDetail();
  // }, [props.MGEventID,props.SiteUserID,props.MGProjectID,props.StartDateTime,props.EndDateTime]);

  useEffect(() => {
    getTasksManagementListsData();
  }, []);

  function getTasksManagementListsData() {
    let object = {} as any;
    object.masterbpid = 0;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/MGReporting/GetTasksManagementListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            ProjectData: l_response[0],
            UserData: l_response[1],
          }));
        } else {
          console.log("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    loadMGTimeLogDetail(true);
  }, [
    props.MGProjectID,
    props.SiteUserID,
    props.StartDateTime,
    props.EndDateTime,
  ]);


  function loadMGTimeLogDetail(showAnimation: boolean) {
    let object = {} as any;
    object.mgeventid = props.MGEventID;
    object.siteuserid = props.SiteUserID;
    object.mgprojectid = props.MGProjectID;
    object.startdatetime = props.StartDateTime;
    object.enddatetime = props.EndDateTime;
    if (showAnimation) setLoadingState(true);
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGEvent/LoadMGTimeLogDetail", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let result = response.data.Data;
          for (let index = 0; index < result[0].length; index++) {
            result[0][index].LogMins = 0;
            result[0][index].LogMins =
              parseInt(result[0][index].DurationInMinutes) +
              parseInt(result[0][index].WastageMinutes);
          }

          setLogsDetailData(result[0]);
        }
        setLoadingState(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function saveTimeLog() {
    let object: any = {};
    object.mgeventstausid = 0;
    object.mgeventid = timerObject.mgeventid; // from props
    object.siteuserid = timerObject.siteuserid; // from props
    object.name = timerObject.name;
    object.startdatetime = timerObject.startdatetime;
    object.enddatetime = timerObject.enddatetime;
    object.durationinminutes = timerObject.durationinminutes;
    object.wastageminutes = timerObject.wastageminutes;
    object.wastagecomments = timerObject.wastagecomments; // any waste, popup
    object.staffcomments = timerObject.staffcomments;
    object.managementcomments = timerObject.managementcomments;
    object.ismanualentry = timerObject.ismanualentry;
    object.manualentrytime = timerObject.manualentrytime;
    object.manuallyenteredbyid = timerObject.manuallyenteredbyid;
    object.ptorderby = timerObject.ptorderby;
    object.objectstatus = timerObject.objectstatus;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGTimeLog/MGTimeLogSaveCustom", object)
      .then((response) => {
        if (response.data.Status === 1) {
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function updateData(index: any, value: any, colName: any) {
    let thislogsDetailData = [...logsDetailData];
    logsDetailData[index][colName] = value;
    setLogsDetailData(thislogsDetailData);
  }

  function deleteThisRecord() {
    updateThisRecord(10, deletePopupObject.seletedID, 0);
    setDeletePopupObject((prevState: any) => ({
      ...prevState,
      showDeletePopUp: false,
      seletedID: 0,
      seletedObject: 0,
    }));
  }

  function updateThisRecord(TypeID: any, MGTimeLogID: any, Value: any) {
    let object: any = {};
    object.mgtimelogid = MGTimeLogID;
    object.typeid = TypeID;
    if (TypeID == 4) {
      object.staffcomments = Value;
    }
    if (TypeID == 2) {
      object.wastageminutes = Value;
    }
    if (TypeID == 5) {
      object.wastagecomments = Value;
    }

    // object.managementcomments = timerObject.managementcomments;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGTimeLog/MGTimeLogSaveCustom", object)
      .then((response) => {
        if (response.data.Status === 1) {
          if (TypeID == 2 || TypeID == 10) {
            loadMGTimeLogDetail(false);
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function updateMGEventData(TypeID: any, MGEventID: any, Value: any) {
    let object: any = {};
    object.mgeventid = MGEventID;
    object.typeid = TypeID;
    if (TypeID == 100) {
      object.mgprojectid = Value;
    }
    if (TypeID == 101) {
      object.projectname = Value;
    }
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGTimeLog/UpdateMGEventData", object)
      .then((response) => {
        if (response.data.Status === 1) {
          loadMGTimeLogDetail(true);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {loadingState && <LoadingAnimation />}
        <div className="p-all10">
          {!props.expandView && (
            <table className="reuse-table">
              <thead>
                <tr>
                  <th>Log Date</th>
                  <th>Log Time</th>
                </tr>
              </thead>
              <tbody>
                {logsDetailData &&
                  logsDetailData.map((Item: any, index: any) => (
                    <tr key={index}>
                      <td>
                        {moment(
                          AppContext.ConvertDateTimeToLocal(Item.StartDateTime)
                        ).format("DD/MM/YYYY")}
                      </td>
                      <td className="text-right">
                        {Item.DurationInMinutes + Item.WastageMinutes}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}

          {props.expandView && (
            <table className="reuse-table">
              <thead>
                <tr>
                  {props.MGEventID != 0 && <th>Start Date Time</th>}
                  {props.MGEventID != 0 && <th>End Date Time</th>}
                  {props.MGEventID == 0 && <th>Log Date</th>}
                  {props.MGEventID == 0 && <th>User</th>}
                  {props.MGEventID == 0 && <th>Project</th>}
                  {props.MGEventID == 0 && <th>Task</th>}
                  <th>Log Minutes</th>
                  <th>Comments</th>
                  <th>Wastage Minutes</th>
                  <th>Wastage Reason</th>
                  <th>Productive Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {logsDetailData &&
                  logsDetailData.map((Item: any, index: any) => (
                    <tr key={index}>
                      {props.MGEventID != 0 && (
                        <td>
                          {moment(
                            AppContext.ConvertDateTimeToLocal(
                              Item.StartDateTime
                            )
                          ).format("DD/MM/YYYY HH:mm")}
                        </td>
                      )}
                      {props.MGEventID != 0 && (
                        <td>
                          {moment(
                            AppContext.ConvertDateTimeToLocal(Item.EndDateTime)
                          ).format("DD/MM/YYYY HH:mm")}
                        </td>
                      )}
                      {props.MGEventID == 0 && (
                        <td>
                          { Item.StartDateTime ? moment(
                            AppContext.ConvertDateTimeToLocal(
                              Item.StartDateTime
                            )
                          ).format("DD/MM/YYYY"):''}
                        </td>
                      )}
                      {props.MGEventID == 0 && (
                        <td>{Item.TeamMember}</td>
                      )}
                      {props.MGEventID == 0 && (
                        <td className="p-all0">
                          {Item.MGProjectID ?  
                          <select
                            disabled={props.MGEventID != 0 ? true : false}
                            className="input--type__text"
                            value={Item.MGProjectID}
                            onChange={(e) =>{
                              updateData(index, e.target.value, "MGProjectID");
                              updateMGEventData(
                                100,
                                Item.MGEventID,
                                e.target.value
                              )}
                            }
                          >
                            {filtersDropdownData.ProjectData &&
                              filtersDropdownData.ProjectData.map(
                                (p_item: any) => (
                                  <option key={p_item.PID} value={p_item.PID}>
                                    {p_item.Name}
                                  </option>
                                )
                              )}
                          </select> :''}
                        </td>
                      )}
                      {props.MGEventID == 0 && (
                        <td className="text-right">
                         {Item.MGTimeLogID ? <input
                            value={Item.EventName}
                            onChange={(e) =>
                              updateData(index, e.target.value, "EventName")
                            }
                            onBlur={(e) =>
                              updateMGEventData(
                                101,
                                Item.MGEventID,
                                e.target.value
                              )
                            }
                          />:""}
                        </td>
                      )}
                      <td className="text-right">{Item.LogMins ? Item.LogMins:''}</td>
                      <td className="p-all0">
                      {Item.MGTimeLogID ?<input
                          value={Item.StaffComments}
                          onBlur={(e) =>
                            updateThisRecord(
                              4,
                              Item.MGTimeLogID,
                              e.target.value
                            )
                          }
                          onChange={(e) =>
                            updateData(index, e.target.value, "StaffComments")
                          }
                        />:""}
                      </td>
                      <td className="p-all0">
                      {Item.MGTimeLogID ? <input
                          className="text-right"
                          value={Item.WastageMinutes}
                          onBlur={(e) =>
                            updateThisRecord(
                              2,
                              Item.MGTimeLogID,
                              e.target.value
                            )
                          }
                          onChange={(e) =>
                            updateData(index, e.target.value, "WastageMinutes")
                          }
                        />:""}
                      </td>
                      <td className="p-all0">
                      {Item.MGTimeLogID ?<input
                          value={Item.WastageComments}
                          onBlur={(e) =>
                            updateThisRecord(
                              5,
                              Item.MGTimeLogID,
                              e.target.value
                            )
                          }
                          onChange={(e) =>
                            updateData(index, e.target.value, "WastageComments")
                          }
                        />:""}
                      </td>
                      <td className="text-right">{Item.DurationInMinutes}</td>
                      <td>
                        <div className="flex-d flex-ac flex-jc flex-gap10">
                          {/* <a>
                            <img
                              src="/images/update.svg"
                              alt="img"
                              width={20}
                              height={20}
                            />
                          </a> */}
                          {Item.MGTimeLogID ?<a
                            onClick={() =>
                              setDeletePopupObject((prevState: any) => ({
                                ...prevState,
                                showDeletePopUp: true,
                                seletedID: Item.MGTimeLogID,
                                seletedObject: Item,
                              }))
                            }
                          >
                            <img
                              src="/images/delete.png"
                              alt="img"
                              width={18}
                              height={18}
                            />
                          </a>:""}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      <Modal show={deletePopupObject.showDeletePopUp}>
        <Modal.Header
          closeButton
          onClick={() =>
            setDeletePopupObject((prevState: any) => ({
              ...prevState,
              showDeletePopUp: false,
              seletedID: 0,
              seletedObject: {},
            }))
          }
        >
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="del--confirm">
            Are you sure you want to dlete this record?
          </div>
          <div className="modal--button del--modal__btn">
            <a
              onClick={() =>
                setDeletePopupObject((prevState: any) => ({
                  ...prevState,
                  showDeletePopUp: false,
                  seletedID: 0,
                  seletedObject: {},
                }))
              }
            >
              No
            </a>
            <a onClick={() => deleteThisRecord()}>Yes</a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
