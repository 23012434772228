import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import AppContext from "../Context/Context";
import DragDrop from "../DragDrop/DragDrop";

import ConfirmationModal from "../App/ConfirmationModal";
import ColumnFilterCheckboxList from "../App/ColumnFilterCheckboxList";
import axiosAuth from "../axios/Interceptors";
import { formateDate } from "../Shared/Utility";
import TasksComm from "./Tasks/TasksComm";
function SupportTickets() {
  const globalState = useGlobalState();
  const [showAnimation, setShowAnimation] = useState(false);
  const [objectAdminData, setObjectAdminData] = useState<any>([]);
  const [isGridView, setIsGridView] = useState(true);
  const [cancelled, setcancelled] = useState(true);
  const [priorityDDL, setPriorityDDL] = useState(false);
  const [selectedObj, setSelectedObj] = useState<any>({});
  const [supportTicketData, setSupportTicketData] = useState<any>([]);

  useEffect(() => {
    getAllSupportTickets();
  }, []);

  function getAllSupportTickets() {
    let object = {} as any;
    object.mgworkspaceid = -1;
    object.mgapplicationid = -1;
    object.mgapplicationpriorityid = -1;
    object.userid = -1;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/MGSupportTicket/GetAllSupportTickets",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_response = response.data.Data;
          // setObjectAdminData(l_response[0]);

          const deepCopy1 = JSON.parse(JSON.stringify(l_response));
          const deepCopy2 = JSON.parse(JSON.stringify(l_response));
          setThisGridListDataWithoutFilters(deepCopy1[0]);
          setObjectAdminData(deepCopy1[0]);
          setThisGridListData(deepCopy2[0]);
          thisGridListDataRef.current = deepCopy2[0];
          setSupportTicketData(deepCopy1);
          console.log(l_response);
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  ///--States for columnFilter components---///
  const [clearFilters, setClearFilters] = useState(false);
  const [refreshFilters, setRefreshFilters] = useState(false);
  const [getFilteredLists, setGetFilteredLists] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState("");
  const [thisGridListDataWithoutFilters, setThisGridListDataWithoutFilters] =
    useState<any>([]);
  const [thisGridListData, setThisGridListData] = useState<any>([]);
  const thisGridListDataRef = useRef<any | null>(null);
  thisGridListDataRef.current = thisGridListData;
  const [clickPosition, setClickPosition] = useState<any>({ x: null, y: null });
  function ShowColumnFilterList(event: any, Name: string) {
    if (Name === selectedColumnName) {
      setSelectedColumnName("");
    } else {
      setClickPosition({ x: event.clientX, y: event.clientY });
      setSelectedColumnName(Name);
    }
  }

  function filterCheckboxListCallback(FilterData: any) {
    setSelectedColumnName("");
    let l_data = []; //[...COPAInvoicesGridData] as any;
    l_data = [...FilterData];
    //setCOPAInvoicesGridData([...l_data]);

    setThisGridListData([...FilterData]);
    thisGridListDataRef.current = [...FilterData];
  }

  function ClearAllFilters() {
    const deepCopy = JSON.parse(JSON.stringify(thisGridListDataWithoutFilters));
    setObjectAdminData(deepCopy[0]);
    setThisGridListData(deepCopy[0]);
    thisGridListDataRef.current = deepCopy[0];
    setClearFilters(!clearFilters);
  }

  //--States for columnFilter components---///

  function updateSupportTicketStatusAndPriority(
    type: any,
    mgsupportticketid: any,
    mgsupportticketstatusid: any,
    mgapplicationpriorityid: any
  ) {
    setShowAnimation(true);
    let object = {} as any;
    object.type = type;
    object.mgsupportticketid = mgsupportticketid;
    object.mgsupportticketstatusid = mgsupportticketstatusid;
    object.mgapplicationpriorityid = mgapplicationpriorityid;
    debugger;
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/MGSupportTicket/UpdateSupportTicketStatusAndPriority",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setShowAnimation(false);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <div className="msg--body">
        <div className="msg--body__lt">
          <div className="msg--body__lt__head flex-d flex-ac flex-jsb p-all0">
            <a className="msg--body__user">
              <span className="msg--body__name">Support Ticket</span>
            </a>
          </div>
          <div className="msg--body__messages p-all10">
            {isGridView == true && (
              <div className="shadow-section">
                <div className="overflow-auto action-last hide-empty pb--0">
                  {objectAdminData && objectAdminData.length > 0 && (
                    <ColumnFilterCheckboxList
                      DataType={1}
                      RefreshFilters={refreshFilters}
                      ClearFilters={clearFilters}
                      GridData={objectAdminData}
                      ColumnName={selectedColumnName}
                      ColumnFilterCheckboxListCallback={
                        filterCheckboxListCallback
                      }
                      GetFilteredLists={getFilteredLists}
                      ReturnAllFilterdLists={{}}
                      ClickPosition={clickPosition}
                      Orientation={"Center"}
                      ColumnOrientation={[
                        { Name: "Name", Orientation: "Right" },
                      ]}
                    />
                  )}

                  <table className="reuse-table">
                    <thead>
                      <tr>
                        <th className="reused-tdlg-min">
                          <div className="flex-d flex-ac flex-jc flex-gap20">
                            <div className="flex-d flex-ac flex-gap10">
                              Summary
                              {/* <a
                                onClick={(e) => ShowColumnFilterList(e, "Name")}
                                data-function="ShowColumnFilterList"
                              >
                                <img
                                  src="images/icon-search-w.svg"
                                  width={15}
                                  height={15}
                                  alt="search"
                                />
                              </a> */}
                            </div>
                          </div>
                        </th>
                        <th className="reused-tdsm">Status</th>
                        <th className="reused-tdsm">Assignee</th>
                        <th className="reused-tdsm">ETA</th>

                        <th className="reused-tdsm">Priority</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thisGridListData &&
                        thisGridListData.map((item: any, index: any) => (
                          <React.Fragment key={index}>
                            {!item.IsExcluded && (
                              <tr
                                className="cursor-p"
                                onClick={() => {
                                  setSelectedObj(item);
                                  setIsGridView(false);
                                  setcancelled(true);
                                }}
                              >
                                <td className="reused-tdlg-min">
                                  {item.Summary}
                                </td>
                                <td className="reused-tdsm">
                                  <div className="flex-d">
                                    <span
                                      className="status-box flex-d flex-ac bold-font text-uppercase"
                                      style={{ background: item.StatusColor }}
                                    >
                                      {item.MGSupportTicketStatusName}
                                    </span>
                                  </div>
                                </td>

                                <td className="reused-tdsm">
                                  {item.RaisedByFirstName}
                                </td>

                                <td className="reused-tdsm">
                                  {formateDate(item.ETADateTime, false)}
                                </td>

                                <td className="reused-tdsm">
                                  <div className="flex-d flex-ac flex-gap7">
                                    <span
                                      className="priority-box flex-d"
                                      style={{ background: item.PriorityColor }}
                                    ></span>
                                    <span className="bold-font text-uppercase">
                                      {item.MGApplicationPriorityName}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {isGridView == false && (
              <>
                <div className="reuse-back-l-g">
                  <div className="flex-d flex-ac flex-jsb reuse-goback">
                    <a
                      className="flex-d flex-ac flex-gap10 bold-font font-12 font-semi"
                      title="Back"
                      onClick={() => {
                        setSelectedObj({});
                        setIsGridView(true);
                        getAllSupportTickets();
                      }}
                    >
                      <img
                        src="images/rightg.png"
                        className="rotate-180d"
                        width={9}
                        height={15}
                      />
                      Back
                    </a>
                  </div>
                  <div className="grid-d fr-41 mt--10 flex-gap20 flex-as">
                    <div className="ib-display w-100 reuse-border">
                      <span className="flex-d flex-ac font-18 bold-font">
                        {" "}
                        {selectedObj.Summary}
                      </span>
                      <span className="flex-d flex-ac bold-font font-14 txt-blue mt--10">
                        {selectedObj.SupportTypeTitle}
                      </span>
                      <span className="flex-d flex-ac font-14">
                        {" "}
                        {selectedObj.Description}
                      </span>
                      <div className="flex-d flex-gap10 ticket-imgs mt--10 wrap-flex">
                        {supportTicketData[1] &&
                          supportTicketData[1].map(
                            (i_item: any, i_index: any) => (
                              <React.Fragment key={i_index}>
                                {selectedObj.MGSupportTicketID ==
                                  i_item.MGSupportTicketID && (
                                  <img
                                    src={i_item.FileURL}
                                    width={100}
                                    height={100}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                      </div>
                    </div>
                    <div className="grid-d flex-gap20">
                      <div className="grid-d">
                        <span className="bold-font font-12">Status</span>
                        <div className="flex-d">
                          <span
                            className="status-box flex-d flex-ac bold-font text-uppercase"
                            style={{ background: selectedObj.StatusColor }}
                          >
                            {selectedObj.MGSupportTicketStatusName}
                          </span>
                        </div>
                      </div>
                      <div className="grid-d">
                        <span className="bold-font font-12">Priority</span>
                        <div className="ib-display w-100 p-relative">
                          <div
                            className="flex-d flex-ac flex-jsb p-box-change"
                            onClick={() => setPriorityDDL(!priorityDDL)}
                          >
                            <span className="txt-grey text-uppercase">
                              {selectedObj.MGApplicationPriorityName}
                            </span>
                            <div className="flex-d flex-ac flex-gap15">
                              <span
                                className="priority-box flex-d"
                                style={{
                                  background: selectedObj.PriorityColor,
                                }}
                              ></span>
                              <img
                                src="images/chev-lib.svg"
                                width={9}
                                height={5}
                              />
                            </div>
                          </div>

                          {priorityDDL && (
                            <div className="priority-drop">
                              {supportTicketData[2] &&
                                supportTicketData[2].map(
                                  (Item: any, index: any) => (
                                    <a
                                      className="flex-d flex-ac flex-jsb"
                                      onClick={() => {
                                        updateSupportTicketStatusAndPriority(
                                          2,
                                          selectedObj.MGSupportTicketID,
                                          0,
                                          Item.MGApplicationPriorityID
                                        );

                                        setSelectedObj((prevState: any) => ({
                                          ...prevState,
                                          MGApplicationPriorityName: Item.Name,
                                          PriorityColor: Item.Color,
                                        }));

                                        setPriorityDDL(false);
                                      }}
                                    >
                                      {Item.Name}
                                      <span
                                        className="priority-box flex-d"
                                        style={{ background: Item.Color }}
                                      ></span>
                                    </a>
                                  )
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex-d flex-ac">
                        {cancelled &&
                          selectedObj.MGSupportTicketStatusID != 3 &&
                          selectedObj.MGSupportTicketStatusID != 4 && (
                            <a
                              className="flex-d flex-ac flex-gap5 txt-red bold-font font-14"
                              onClick={() => {
                                updateSupportTicketStatusAndPriority(
                                  1,
                                  selectedObj.MGSupportTicketID,
                                  4,
                                  0
                                );
                                setcancelled(false);
                              }}
                            >
                              <img
                                src="images/r-cross.svg"
                                width={15}
                                height={15}
                              />
                              Cancel Request
                            </a>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Support-chat">
                  <span className="flex-d bold-font mt--15">Activity</span>
                  <TasksComm
                    workspaceid={selectedObj.MGWorkspaceID}
                    chennelid={selectedObj.GeneralChannelCode}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default SupportTickets;
